import React from "react";
import { Mail, Linkedin, GitHub, FileText } from "react-feather";
import { Resume, Betty } from "../assets";
import "./About.css";

const About: React.FC = () => {
  return (
    <div id="about">
      <h1>about.</h1>
      <div className="about-content">
        <img src={Betty} className="profile-pic" />
        <div>
          <p>
            Hi, I'm Betty! I am a computer science and business student who
            aspires to build solutions to the world’s problems. Currently I'm
            working on growing my skills and gaining exposure to a variety of
            technology, whether that be through school or personal projects.
            When I'm not glued to my computer, you can find me devouring a fun
            read, watering my plants, or taking walks around the neighborhood!
            Feel free to <a href={Resume}> download my resume </a> or contact me
            for a quick chat over coffee:{" "}
            <a href="mailto:yuxuan.betty.guo@outlook.com">
              yuxuan.betty.guo@outlook.com
            </a>
          </p>
          <div className="icons">
            <a href="mailto:yuxuan.betty.guo@outlook.com">
              <Mail color="#f8b899" className="icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/betty-guo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin color="#f8b899" className="icon" />
            </a>
            <a
              href="https://github.com/betty-guo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHub color="#f8b899" className="icon" />
            </a>
            <a href={Resume} target="_blank">
              <FileText color="#f8b899" className="icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
