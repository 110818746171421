import React, { useState, useEffect } from 'react';
import Intro from './pages/Intro';
import About from './pages/About';
import Experience from './pages/Experience';
import Projects from './pages/Projects';
import Typist from 'react-typist';
import { Resume } from './assets';
import { Menu as MenuIcon } from 'react-feather'
import { Menu, Dropdown} from 'antd';
import { Mail, Linkedin, GitHub } from 'react-feather';
import './App.css';
import 'antd/dist/antd.css';

const App: React.FC = () => {

  const menu = (
    <Menu>
      <Menu.Item><a href="#">Home</a></Menu.Item>
      <Menu.Item><a href="#about">About Me</a></Menu.Item>
      <Menu.Item><a href="#experience">Experience</a></Menu.Item>
      <Menu.Item><a href="#projects">Projects</a></Menu.Item>
      <Menu.Item><a href={Resume} target="_blank" rel="noopener noreferrer">Resume</a></Menu.Item>
    </Menu>
  );

  const [count, setCount] = useState(1);

  useEffect(() => {
    // document.title = `You clicked ${count} times`;
    console.log("Count: " + count);
    setCount(1);
  }, [count]);

  return (
    <div className="App">
      <Dropdown overlay={menu} className="hamburger" overlayClassName="overlay">
        <a><MenuIcon /></a>
      </Dropdown>
      <Menu />
      <Intro />
      <About />
      <Experience />
      <Projects />
      <div className="bottom-bar">
        <div className="copyright">&copy; Betty Guo 2020</div>
        <div className="bottom-text">
          {count ? (
            <Typist cursor={{ show: false }} avgTypingDelay={50} onTypingDone={() => setCount(0)}>
            <p>
                <span className="word">&nbsp;Built by Betty with &hearts; and &#9749;</span>
                <Typist.Backspace count={21} delay={1000} />
                <span className="word">with HTML/CSS, React & Typescript</span>
                <Typist.Backspace count={39} delay={1000} />
                <span className="word">Last Updated: April 2020</span>
                <Typist.Backspace count={24} delay={1000} />
                <span className="word">Stay tuned for more updates!</span>
                <Typist.Backspace count={26} delay={1000} />
            </p>
            </Typist>
          ) : (
            ""
          )}
        </div>
        <div className="icons">
          <a href="mailto:yuxuan.betty.guo@outlook.com"><Mail className="icon" /></a>
          <a href="https://www.linkedin.com/in/betty-guo" target="_blank" rel="noopener noreferrer"><Linkedin className="icon" /></a>
          <a href="https://github.com/betty-guo" target="_blank" rel="noopener noreferrer"><GitHub className="icon" /></a>
        </div>
      </div>
    </div>
  );
}

export default App;
