import React, { useState, useEffect } from 'react';
import { Mail, Linkedin, GitHub, FileText } from 'react-feather';
import { Resume } from '../assets';
import './Intro.css';
import Typist from 'react-typist';
import BettyLogo from '../assets/images/BG.png';

const Intro: React.FC = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    // document.title = `You clicked ${count} times`;
    console.log("Count: " + count);
    setCount(1);
  }, [count]);

  return (
    <div className="home">
      <div className="home-container">
       <div className="mobileIntro">
       <h1> Betty Guo </h1>
       <div className="icons">
         <a href="mailto:yuxuan.betty.guo@outlook.com"><Mail className="icon" /></a>
         <a href="https://www.linkedin.com/in/betty-guo" target="_blank" rel="noopener noreferrer"><Linkedin className="icon" /></a>
         <a href="https://github.com/betty-guo" target="_blank" rel="noopener noreferrer"><GitHub className="icon" /></a>
         <a href={Resume} target="_blank" rel="noopener noreferrer"><FileText className="icon" /></a>
       </div>
       </div>
        <div className="textAnimation">
          {count ? (
            <Typist cursor={{show: false}} avgTypingDelay={100} onTypingDone={() => setCount(0)}>
            <h1 className="intro"> Hi! I'm </h1>
            <h1>
                <span className="word col1">Betty.</span>
                 <Typist.Backspace count={6} delay={1500} />
                 <Typist.Delay ms={200} />
                <span className="word col2">a student.</span>
                <Typist.Backspace count={10} delay={1500} />
                <Typist.Delay ms={200} />
                <span className="word col3">an aspiring dev.</span>
                <Typist.Backspace count={16} delay={1500} />
                <Typist.Delay ms={200} />
                <span className="word col4">a change-maker.</span>
                <Typist.Backspace count={23} delay={1500} />
            </h1>
            </Typist>
          ) : (
            ""
          )}
        </div>
        <div className="background-animation">
          <div className="lg-paper paper-1">
            <a href="https://www.linkedin.com/in/betty-guo/" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-linkedin" />
            </a>
          </div>
          <div className = "bg">
            <img src={BettyLogo} className="bettylogo" height="20"/>
          </div>
          <div className="sm-paper paper-2" />
          <div className="lg-paper paper-3">
            <a
              href="mailto:yuxuan.betty.guo@outlook.com"
              itemProp="email"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-envelope" />
            </a>
          </div>
          <div className="me">
            <div className="me-body" />
            <div className="me-head">
              <div className="hair" />
              <div className="face">
                <div className="eye-left" />
                <div className="eye-right" />
                <div className="mouth" />
              </div>
              <div className="hair-front-1" />
              <div className="hair-front-2" />
            </div>
          </div>
          <div className="table">
            <div className="bowl">
              <div className="stem">
                <div className="leaf-1" />
                <div className="leaf-2" />
                <div className="leaf-3" />
                <div className="leaf-4" />
              </div>
            </div>
            <div className="notebook">
              <a href="https://github.com/betty-guo/" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-github-square" />
              </a>
              <div className="apple" />
            </div>
            <div className="bowl-2">
              <div className="leaf-5" />
              <div className="leaf-6" />
              <div className="leaf-7" />
              <div className="leaf-8" />
              <div className="leaf-9" />
            </div>
            <div className="bowl-3">
              <div className="leaf-5" />
              <div className="leaf-6" />
              <div className="leaf-7" />
              <div className="leaf-8" />
              <div className="leaf-9" />
            </div>
            <div className="book-1" />
            <div className="book-2" />
            <div className="book-3" />
            <div className="coffee">
              <div className="smoke" />
            </div>
            <div className="box">
              <div className="circle" />
              <div className="circle circle-2" />
            </div>
            <div className="box-2">
              <div className="circle" />
              <div className="circle circle-3" />
            </div>
            <div className="section">
              <p>uw cs/bba '24 | Currently seeking Winter 2022 Internship</p>
              <p>
                See my <a href={Resume}>resume</a> or scroll down to learn
                more about me!
              </p>
            </div>
          </div>
      </div>
      <div id = "arrow">
          <div className="arrow_part">
              <span></span>
              <span></span>
              <span></span>
          </div>
      </div>
      </div>
    </div>
  );
}

export default Intro;
