import React from 'react';
import { Timeline } from 'antd';
import {Sunlife, WiSTEM, LleTTonna, Crania, DECA, Magnet} from "../assets";
import './Experience.css';

const { Item } = Timeline;

const Experience: React.FC = () => {
  return (
    <div id="experience">
      <h1>experience.</h1>
      <Timeline>
      <Item dot={<img className="timeline-dot" src={Magnet} />}>
        <h2>Magnet Forensics</h2>
        <h4>Software Developer | January 2021 - present</h4>
        <ul>
          <li>
          Engineered data sources and filters integral to new media investigation tool with C# and XAML, used by law enforcement and digital forensics investigators to compile case evidence
          </li>
          <li>
          Leveraged Apache Lucene.NET open source search engine library to efficiently implement robust media searching capabilities
          </li>
          <li>
          Implemented end-to-end business logic in areas such as case exporting and evidence visualization to enhance investigator experience, utilizing MVVM architecture and design patterns
          </li>
          <li>
          Automated unit and functional test coverage for new and existing code using Moq and xUnit
          </li>
        </ul>
      </Item>
      <Item dot={<img className="timeline-dot" src={LleTTonna} />}>
        <h2>lleTTonna</h2>
        <h4>Project Manager - Web Application Team | April 2020 - December 2020</h4>
        <ul>
          <li>
              Leading a team of seven student developers in the creation of lleTTonna's web application platform that aims to aid mothers in postpartum
          </li>
          <li>
              Determining project scope and objectives while developing detailed project schedules, work plans and budgets
          </li>
          <li>
              Providing project updates on a consistent basis to various stakeholders about strategy, adjustments and progress
          </li>
        </ul>
      </Item>
      <Item dot={<img className="timeline-dot" src={Sunlife} />}>
        <h2>Sunlife Financial</h2>
        <h4>Innovation Web Application Developer | May 2020 - August 2020</h4>
        <ul>
        <li>
          Explored new technology implementations through proof-of-concept development in the Sun Life Innovation Lab through collaboration with Agile team and stakeholders
          </li>
          <li>
          Pitched 8 wireframes and 5 coded prototypes to over 50 individuals as potential production projects
          </li>
          <li>
          Coordinated between business, IT and innovation teams to iterate on customer-focused designs
          </li>
          <li>
            Championed research, design and prototyping of FIDO2 compliant biometric authentication within existing Sun Life IT ecosystem
          </li>
          <li>
          Studied the architecture of Amazon Alexa voice skills and developed client-centric skill leveraging geolocation and Alexa VUI.
          </li>
          <li>
          Designed and built new website for Women in Insurance STEM program using Adobe XD, ReactJS and CSS
          </li>
        </ul>
      </Item>
      <Item dot={<img className="timeline-dot" src={Crania} />}>
          <h2>Crania Schools</h2>
          <h4>Junior Programmer/ Math Instructor | July 2018 - Nov 2019</h4>
          <ul>
            <li>
                Implemented custom Zoho Creator app using new Zoho deluge script within one month; automated administrative tasks to reduce manual data entry for startup owner
            </li>
            <li>
                Automated student registration process using RESTful APIs; improved user experience for 300+ parent/guardians
            </li>
            <li>
              Taught fundamental math skills to children aged 5-13; improved their confidence and calculation accuracy
            </li>
          </ul>
        </Item>
        <Item dot={<img className="timeline-dot blueprint" src={WiSTEM} />}>
          <h2>WCI WiSTEM</h2>
          <h4>Co-Founder| Sep 2018 - June 2019</h4>
          <ul>
            <li>
            Conducted bi-weekly presentations to inspire and inform a group of 34 high school girls on the opportunities in STEM
            </li>
            <li>
            Invited 6 female guest speakers within the school and local community to share their experiences in STEM
            </li>
          </ul>
        </Item>
        <Item dot={<img className="timeline-dot" src={DECA} />}>
          <h2>WCI DECA</h2>
          <h4>Co-President and Workshop Officer | May 2017 - June 2018</h4>
          <ul>
            <li>
              Organized weekly chapter training sessions; increased provincial qualification rate by 20% and international qualification rate by 70% from previous year reaching an all-time high for the chapter
            </li>
            <li>
              Created tiered sponsorship plan and marketed to local businesses; raised $2000 for chapter
            </li>
          </ul>
        </Item>
      </Timeline>
    </div>
  );
}

export default Experience;
